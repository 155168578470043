<style lang="postcss">
.top-table-content {
  @apply text-left mt-8 md:mt-12 mx-auto w-max relative md:-left-20 pb-10;
  max-width: 100%;
}
.top-subtitle {
  @apply font-sb_sans_display mb-8 md:mb-12 ml-5;
  font-size: 16px;
  line-height: 110%;
  color: rgba(255, 255, 255, 0.8);
  & li {
    @apply mb-2;
    list-style-type: disclosure-open;
  }
}
@screen md {
  .top-subtitle {
    font-size: 20px;
    line-height: 110%;
  }
}
</style>

<template>
  <div class="top-table-content">
    <router-link
      class="sb-btn sb-btn--outline sb-btn--sm mb-5"
      :to="{ name: 'Stages' }"
      >Назад</router-link
    >
    <h2 class="title-secondary mb-2 md:mb-4">{{ title }}</h2>
    <ul class="top-subtitle">
      <li v-for="item in schedule" :key="item" v-html="item"></li>
    </ul>
    <top-table :players="leaderboard.top" :user="leaderboard.current" />
  </div>
</template>

<script>
import TopTable from '@/components/TopTable.vue';
import { CONF_SETTINGS } from "@/confSettings";

export default {
  components: { TopTable },
  name: 'TopPage',
  data() {
    return {
      title: 'Топ 15',
      schedule: CONF_SETTINGS.schedule,
    };
  },
  async created() {
    await this.$store.dispatch('game/fetchLeaderboard');
  },
  methods: {},
  computed: {
    leaderboard() {
      return this.$store.state.game.leaderboard;
    },
  },
};
</script>
